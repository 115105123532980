:root {
  --font-family: "Roboto-Medium", sans-serif;
  --second-family: "Bebas Neue Cyrillic", sans-serif;
  --third-family: "Bebas Neue", sans-serif;
  --content-width: 1344px;
  --container-offset: 48px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --text-color: #c6c6c6;
  --grey-color: #1a1a1a;
  --dark-color: #000;
  --red-color: #f42828;
  --16-12: clamp(0.75rem, 0.679rem + 0.357vw, 1rem);
  --20-12: clamp(0.75rem, 0.607rem + 0.714vw, 1.25rem);
  --20-14: clamp(0.875rem, 0.768rem + 0.536vw, 1.25rem);
  --20-16: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
  --24-16: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
  --32-16: clamp(1rem, 0.714rem + 1.429vw, 2rem);
  --32-20: clamp(1.25rem, 1.036rem + 1.071vw, 2rem);
  --40-24: clamp(1.5rem, 1.214rem + 1.429vw, 2.5rem);
  --64-48: clamp(3rem, 2.714rem + 1.429vw, 4rem);
  --96-48: clamp(3rem, 2.143rem + 4.286vw, 6rem);
  --120-48: clamp(3rem, 1.714rem + 6.429vw, 7.5rem);
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/../fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Neue Cyrillic";
  src: url("../fonts/../fonts/Bebas-Neue-Cyrilic.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Bebas Neue";
  src: url("../fonts/../fonts/Bebas-Neue-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: var(--dark-color);
}

img {
  height: auto;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--text-color);
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 50px var(--container-offset);
  max-width: var(--container-width);
  color: var(--text-color);
}
@media (max-width: 576px) {
  .container {
    padding: 24px 8px;
  }
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.burger .line {
  transition: top 0.2s 0.2s, transform 0.2s, opacity 0.2s;
}

.openbtn {
  background: #141414;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.openbtn .openbtn-area {
  transition: all 0.4s;
  width: 46px;
  height: 46px;
}

.openbtn span {
  position: absolute;
  background: #fff;
  display: inline-block;
  left: 12px;
  height: 2px;
  transition: all 0.4s;
  width: 50%;
}
.openbtn span:nth-of-type(1) {
  top: 14px;
}
.openbtn span:nth-of-type(2) {
  top: 22px;
}
.openbtn span:nth-of-type(3) {
  top: 30px;
}

.openbtn.burger--active .openbtn-area {
  transform: rotate(360deg);
}

.openbtn.burger--active span:nth-of-type(1) {
  width: 45%;
  top: 16px;
  left: 14px;
  transform: translateY(6px) rotate(-45deg);
}
.openbtn.burger--active span:nth-of-type(2) {
  opacity: 0;
}
.openbtn.burger--active span:nth-of-type(3) {
  width: 45%;
  top: 28px;
  left: 14px;
  transform: translateY(-6px) rotate(45deg);
}

.burger {
  display: none;
  color: var(--light-color);
}
@media (max-width: 768px) {
  .burger {
    display: block;
  }
}

.nav.menu--active {
  visibility: visible;
  transform: none;
  display: flex;
  opacity: 1;
  height: 100%;
  overflow: scroll;
}

@media (max-width: 768px) {
  .nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 150px 25px 25px 25px;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    visibility: hidden;
    background-color: #000000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}

.section-title {
  margin: 0;
  margin-bottom: 24px;
  font-family: var(--second-family);
  font-size: var(--96-48);
  line-height: 125%;
  text-align: center;
  color: #c6c6c6;
}

.btn {
  padding: 20px 100px;
  font-size: var(--24-16);
  line-height: 100%;
  border-radius: 8px;
  font-family: var(--second-family);
  background-color: var(--red-color);
  transition: transform 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .btn:hover {
    transform: scale(1.05);
  }
}
.btn--secondary {
  border-radius: 44px;
  padding: 12px 24px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--20-12);
  line-height: 120%;
  background-color: var(--grey-color);
  transition: background-color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .btn--secondary:hover {
    background-color: var(--red-color);
    transform: none;
  }
}

.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2392156863);
}
@media (max-width: 768px) {
  .header {
    border: none;
  }
}
.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 24px;
}
@media (max-width: 768px) {
  .header__container {
    flex-direction: row;
    justify-content: space-between;
  }
}
.header__link {
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .header__link {
    position: relative;
    margin-bottom: 0;
    z-index: 1;
  }
}
.header__nav {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 817px;
  column-gap: 10px;
}
@media (max-width: 768px) {
  .header__nav {
    padding: 150px 8px 23px 8px;
  }
}
.header__nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 768px) {
  .header__nav-list {
    flex-direction: column;
    margin-bottom: 77px;
    height: 100%;
  }
}
.header__nav-item {
  margin-right: 15px;
}
@media (max-width: 768px) {
  .header__nav-item {
    margin-right: 0;
  }
}
.header__nav-link {
  font-weight: 500;
  font-size: 15px;
  line-height: 133%;
  color: var(--text-color);
  transition: color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .header__nav-link:hover {
    color: var(--red-color);
  }
}
@media (max-width: 768px) {
  .header__nav-link {
    font-family: var(--second-family);
    font-size: 40px;
  }
}

.social-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .social-list {
    justify-content: center;
  }
}
.social-list__item:not(:last-child) {
  margin-right: 24px;
}
.social-list__link svg path {
  transition: fill 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .social-list__link:hover svg path {
    fill: var(--red-color);
  }
}

.hero__container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .hero__container {
    padding-top: 48px;
  }
}
.hero__uptitle {
  display: flex;
  margin-bottom: 48px;
  column-gap: 32px;
}
@media (max-width: 768px) {
  .hero__uptitle {
    margin-bottom: 28px;
  }
}
.hero__uptitle-left, .hero__uptitle-right {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.hero__uptitle svg {
  margin-bottom: 4px;
}
.hero__uptitle-text {
  margin: 0;
  font-weight: 500;
  font-size: var(--16-12);
  line-height: 100%;
  letter-spacing: -0.01em;
}
.hero__title {
  margin: 0;
  margin-bottom: 16px;
  font-family: var(--second-family);
  font-size: var(--120-48);
  line-height: 100%;
}
.hero__subtitle {
  margin: 0;
  margin-bottom: 48px;
  max-width: 720px;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: var(--32-16);
  line-height: 100%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #c6c6c6;
}
.hero__btn {
  margin-bottom: 48px;
}
@media (max-width: 768px) {
  .hero__btn {
    margin-bottom: 28px;
  }
}

.counter__list {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 877px) {
  .counter__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
}
@media (max-width: 576px) {
  .counter__list {
    display: flex;
    flex-direction: column;
  }
}
.counter__list-item:last-child {
  grid-column: 2/span 2;
}
.counter__display-amount {
  margin: 0;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: var(--64-48);
  line-height: 100%;
  text-align: center;
  color: #f42828;
}
.counter__display-title {
  margin: 0;
  margin-bottom: 16px;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: var(--40-24);
  line-height: 160%;
  text-align: center;
  color: #d6d6d6;
}

.swiper-button-next, .swiper-button-prev {
  padding: 14px;
  width: 52px;
  height: 52px;
  border-radius: 12px;
  background-color: #242424;
  transition: background-color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .swiper-button-next:hover, .swiper-button-prev:hover {
    background-color: var(--red-color);
  }
}
.swiper-button-next::after, .swiper-button-prev::after {
  content: unset;
}

@media (max-width: 576px) {
  .gallery__list {
    flex-wrap: nowrap;
  }
  .gallery__list.swiper {
    width: 100%;
  }
  .gallery__list-item.swiper-slide {
    width: auto;
    flex-shrink: 0;
  }
  .swiper-wrapper {
    display: flex;
  }
}
.swiper.people__slider {
  width: 100%;
  margin-bottom: 24px;
}
.swiper.people__slider .swiper-button-next, .swiper.people__slider .swiper-button-prev {
  margin-top: calc(-7px - var(--swiper-navigation-size) / 2);
}
@media (max-width: 576px) {
  .swiper.people__slider .swiper-button-next, .swiper.people__slider .swiper-button-prev {
    display: none;
  }
}
.swiper.people__slider .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 0px);
}
.swiper.people__slider .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 0px);
}

.swiper--after,
.swiper--past {
  overflow: visible;
}
.swiper--after .swiper-button-next, .swiper--after .swiper-button-prev,
.swiper--past .swiper-button-next,
.swiper--past .swiper-button-prev {
  background-color: #e6e6e6;
  transition: background-color 0.3s ease-in-out;
}
@media (any-hover: hover) {
  .swiper--after .swiper-button-next:hover, .swiper--after .swiper-button-prev:hover,
  .swiper--past .swiper-button-next:hover,
  .swiper--past .swiper-button-prev:hover {
    background-color: var(--red-color);
  }
}
@media (max-width: 576px) {
  .swiper--after .swiper-button-next, .swiper--after .swiper-button-prev,
  .swiper--past .swiper-button-next,
  .swiper--past .swiper-button-prev {
    display: none;
  }
}
.swiper--after .swiper-button-next,
.swiper--past .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 0px);
}
.swiper--after .swiper-button-prev,
.swiper--past .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 0px);
}

.gallery__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 139px);
  grid-auto-flow: dense;
  column-gap: 16px;
  row-gap: 20px;
}
.gallery__list-item {
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;
}
.gallery__list-item:nth-child(10n+1) {
  grid-column: 1/span 1;
  grid-row: span 3;
}
.gallery__list-item:nth-child(10n+2) {
  grid-column: 2/span 1;
  grid-row: span 2;
}
.gallery__list-item:nth-child(10n+3) {
  grid-column: 3/span 2;
  grid-row: span 3;
}
.gallery__list-item:nth-child(10n+4) {
  grid-column: 1/span 1;
  grid-row: span 2;
}
.gallery__list-item:nth-child(10n+5) {
  grid-column: 2/span 1;
  grid-row: span 2;
}
.gallery__list-item:nth-child(10n+6) {
  grid-column: 3/span 1;
  grid-row: span 2;
}
.gallery__list-item:nth-child(10n+7) {
  grid-column: 4/span 1;
  grid-row: span 2;
}
.gallery__list-item:nth-child(10n+8) {
  grid-column: 1/span 1;
  grid-row: span 2;
}
.gallery__list-item:nth-child(10n+9) {
  grid-column: 2/span 1;
  grid-row: span 3;
}
.gallery__list-item:nth-child(10n+10) {
  grid-column: 3/span 2;
  grid-row: span 2;
}
.gallery__list-item img {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}
@media (max-width: 576px) {
  .gallery__list-item {
    width: auto;
    height: 100%;
    max-height: 300px;
  }
}

@media (max-width: 576px) {
  .people .gallery__list {
    display: flex;
  }
}
.btn--people {
  position: relative;
  padding: 12px;
  text-transform: uppercase;
  font-family: var(--second-family);
  overflow: hidden;
  border-radius: 36px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
}
@media (max-width: 576px) {
  .btn--people {
    padding: 2px;
    border-radius: 20px;
  }
}
.btn--people span {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: flex;
  margin-top: -10px;
  padding: 5px 4px 0px 4px;
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  border-radius: 4px;
  background-color: #9A0019;
  transform: translateX(-50%);
}
@media (max-width: 576px) {
  .btn--people span {
    bottom: 7px;
    padding: 5px 3px 0px 3px;
    font-size: 12px;
  }
}
@media (any-hover: hover) {
  .btn--people:hover {
    border-color: #f00;
  }
}

.glide__track {
  overflow: visible;
}
.glide__arrow {
  position: absolute;
  top: 50%;
  padding: 14px 16px;
  margin-top: -9px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 12px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: var(--text-color);
  z-index: 1;
}
.glide__arrow--left {
  left: 0;
}
.glide__arrow--right {
  right: 0;
}
.glide__arrow svg {
  transition: stroke 0.3 ease-in-out;
}
@media (any-hover: hover) {
  .glide__arrow:hover {
    background-color: var(--red-color);
  }
  .glide__arrow:hover svg path {
    stroke: #fff;
  }
}

.glide__slide {
  width: auto !important;
  height: 100% !important;
}

.past__list {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.past__list-item:not(:last-child) {
  margin-right: 24px;
}

.request__wrap {
  padding: 91px 30px;
  padding-bottom: 151px;
  border-radius: 16px;
  background-image: image-set(url("../img/request-bg.webp") 1x, url("../img/request-bg_2x.webp") 2x);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media (max-width: 768px) {
  .request__wrap {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 64px;
  }
}
.request__title {
  margin-bottom: 76px;
}
@media (max-width: 768px) {
  .request__title {
    margin-bottom: 24px;
  }
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  max-width: 1132px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
.form__left {
  background-color: var(--dark-color);
  border: 1.5px solid #3d3d3d;
  border-radius: 8px;
  padding: 28px 20px;
}
@media (max-width: 768px) {
  .form__left {
    order: 2;
  }
}
.form__right {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 17px;
}
@media (max-width: 1024px) {
  .form__right {
    gap: unset;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .form__right {
    display: contents;
    gap: 12px;
  }
  .form__right .form__label {
    order: 1;
  }
  .form__right .form__btn {
    order: 3;
  }
}
.form__input {
  padding: 22px 16px;
  width: 100%;
  border: 1.5px solid rgba(255, 255, 255, 0.24);
  border-radius: 8px;
  background-color: #0f0f0f;
}
.form__btn {
  width: 100%;
}
.form__link {
  position: absolute;
  bottom: -50px;
  left: 50%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--20-14);
  line-height: 120%;
  transform: translateX(-50%);
  color: var(--text-color);
}
@media (max-width: 768px) {
  .form__link {
    bottom: 22px;
  }
}

.photo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#picture__input1,
#picture__input2,
#picture__input3,
#picture__input4,
#picture__input5,
#picture__input6 {
  display: none;
}

.picture {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 158px;
  background-color: #0f0f0f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  border: 2px dashed rgba(255, 255, 255, 0.24);
  border-radius: 12px;
  cursor: pointer;
  font-size: var(--20-16);
  transition: color 300ms ease-in-out, background 300ms ease-in-out, border-color 300ms ease-in-out;
}
@media (max-width: 576px) {
  .picture {
    height: 115px;
  }
}

.picture--circle {
  margin-bottom: 16px;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: none;
  overflow: hidden;
}

.picture:hover {
  color: #fff;
  background: #444;
  border-color: #aaa;
}

.picture:active {
  border-color: turquoise;
  color: turquoise;
  background: #555;
}

.picture:focus {
  color: #fff;
  background: #444;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

.picture__img {
  max-width: 100%;
  max-height: 100%;
  border-radius: inherit;
}

.photo-section,
.works-section {
  text-align: center;
}

.works-section-title {
  margin: 0;
  margin-bottom: 20px;
  font-family: var(--second-family);
  font-size: 24px;
}

.works-section .picture-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 1024px) {
  .works-section .picture-group {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .works-section .picture-group {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 576px) {
  .works-section .picture-group {
    grid-template-columns: repeat(2, 1fr);
  }
}
.works-section .picture-group span {
  grid-template-columns: 1 span;
}

.photo-caption {
  margin: 0;
  margin-bottom: 36px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--20-16);
  line-height: 120%;
  color: rgba(255, 255, 255, 0.36);
}

.d-none {
  display: none;
}

.after__descr {
  margin: 0 auto;
  margin-bottom: 24px;
  max-width: 776px;
  font-family: var(--second-family);
  font-weight: 400;
  font-size: var(--32-16);
  line-height: 100%;
  letter-spacing: -0.01em;
  text-align: center;
}

.sponsors__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
}
@media (max-width: 768px) {
  .sponsors__title {
    margin-bottom: 48px;
  }
}
.sponsors__list {
  display: flex;
  gap: 48px;
  margin-bottom: 100px;
}
@media (max-width: 768px) {
  .sponsors__list {
    margin-bottom: 48px;
  }
}
.sponsors__descr {
  margin: 0;
  font-family: var(--third-family);
  font-weight: 700;
  font-size: var(--32-20);
  line-height: 100%;
  color: #c6c6c6;
  text-align: center;
}