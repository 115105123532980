.request {

  &__wrap {
    padding: 91px 30px;
    padding-bottom: 151px;
    border-radius: 16px;
    background-image: image-set(url('../img/request-bg.webp') 1x,
        url('../img/request-bg_2x.webp') 2x);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include small-tablet {
      position: relative;
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 64px;
    }
  }

  &__title {
    margin-bottom: 76px;

    @include small-tablet {
      margin-bottom: 24px;
    }
  }
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  max-width: 1132px;
  margin: 0 auto;

  @include small-tablet {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__left {
    background-color: var(--dark-color);
    border: 1.50px solid #3d3d3d;
    border-radius: 8px;
    padding: 28px 20px;

    @include small-tablet {
      // display: contents;
      order: 2;
    }
  }

  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 17px;

    @include tablet {
      gap: unset;
      justify-content: space-between;
    }

    @include small-tablet {
      display: contents;
      gap: 12px;

      & .form__label {
        order: 1;
      }

      & .form__btn {
        order: 3;
      }
    }
  }

  &__input {
    padding: 22px 16px;
    width: 100%;
    border: 1.50px solid rgba(255, 255, 255, 0.24);
    border-radius: 8px;
    background-color: #0f0f0f;
  }

  &__btn {
    width: 100%;
  }

  &__link {
    position: absolute;
    bottom: -50px;
    left: 50%;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: var(--20-14);
    line-height: 120%;
    transform: translateX(-50%);
    color: var(--text-color);

    @include small-tablet {
      bottom: 22px;
    }
  }

}

.photo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#picture__input1,
#picture__input2,
#picture__input3,
#picture__input4,
#picture__input5,
#picture__input6 {
  display: none;
}

.picture {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 158px;
  background-color: #0f0f0f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  border: 2px dashed rgba(255, 255, 255, 0.24);
  border-radius: 12px;
  cursor: pointer;
  font-size: var(--20-16);
  transition: color 300ms ease-in-out, background 300ms ease-in-out, border-color 300ms ease-in-out;

  @include mobile {
    height: 115px;
  }
}

.picture--circle {
  margin-bottom: 16px;
  width: 122px;
  height: 122px;
  border-radius: 50%;
  border: none;
  overflow: hidden;
}

.picture:hover {
  color: #fff;
  background: #444;
  border-color: #aaa;
}

.picture:active {
  border-color: turquoise;
  color: turquoise;
  background: #555;
}

.picture:focus {
  color: #fff;
  background: #444;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.3);
}

.picture__img {
  max-width: 100%;
  max-height: 100%;
  border-radius: inherit;
}

.photo-section,
.works-section {
  text-align: center;
  // margin-bottom: 20px;
}

.works-section-title {
  margin: 0;
  margin-bottom: 20px;
  font-family: var(--second-family);
  font-size: 24px;
}

.works-section .picture-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include small-tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mobile {
    grid-template-columns: repeat(2, 1fr);

  }

  & span {
    grid-template-columns: 1 span;
  }
}

.photo-caption {
  margin: 0;
  margin-bottom: 36px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--20-16);
  line-height: 120%;
  color: rgba(255, 255, 255, 0.36);
}

.d-none {
  display: none;
}
