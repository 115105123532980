.counter {

  &__list {
    display: flex;
    justify-content: space-between;

    @media (max-width:877px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    &-item {

      &:last-child {
        grid-column: 2 / span 2;
      }
    }

  }

  &__display {

    &-amount {
      margin: 0;
      font-family: var(--second-family);
      font-weight: 400;
      font-size: var(--64-48);
      line-height: 100%;
      text-align: center;
      color: #f42828;
    }

    &-title {
      margin: 0;
      margin-bottom: 16px;
      font-family: var(--second-family);
      font-weight: 400;
      font-size: var(--40-24);
      line-height: 160%;
      text-align: center;
      color: #d6d6d6;
    }
  }

}
