.after {

  &__descr {
    margin: 0 auto;
    margin-bottom: 24px;
    max-width: 776px;
    font-family: var(--second-family);
    font-weight: 400;
    font-size: var(--32-16);
    line-height: 100%;
    letter-spacing: -0.01em;
    text-align: center;
  }
}
