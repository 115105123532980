.past {


  &__list {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    &-item {

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
