.hero {

  &__container {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include small-tablet {
      padding-top: 48px;
    }
  }

  &__uptitle {
    display: flex;
    margin-bottom: 48px;
    column-gap: 32px;

    @include small-tablet {
      margin-bottom: 28px;
    }

    &-left,
    &-right {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    & svg {
      margin-bottom: 4px;
    }

    &-text {
      margin: 0;
      font-weight: 500;
      font-size: var(--16-12);
      line-height: 100%;
      letter-spacing: -0.01em;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 16px;
    font-family: var(--second-family);
    font-size: var(--120-48);
    line-height: 100%;
  }

  &__subtitle {
    margin: 0;
    margin-bottom: 48px;
    max-width: 720px;
    font-family: var(--second-family);
    font-weight: 400;
    font-size: var(--32-16);
    line-height: 100%;
    letter-spacing: -0.01em;
    text-align: center;
    color: #c6c6c6;
  }

  &__btn {
    margin-bottom: 48px;

    @include small-tablet {
      margin-bottom: 28px;
    }
  }

}
