.header {
  border-bottom: 1px solid #ffffff3d;

  @include small-tablet {
    border: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 24px;

    @include small-tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__link {
    margin-bottom: 24px;

    @include small-tablet {
      position: relative;
      margin-bottom: 0;
      z-index: 1;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 817px;
    column-gap: 10px;

    @include small-tablet {
      padding: 150px 8px 23px 8px;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include small-tablet {
        flex-direction: column;
        margin-bottom: 77px;
        height: 100%;
      }
    }

    &-item {
      margin-right: 15px;

      @include small-tablet {
        margin-right: 0;
      }
    }

    &-link {
      font-weight: 500;
      font-size: 15px;
      line-height: 133%;
      color: var(--text-color);
      transition: color .3s ease-in-out;

      @include hover {
        color: var(--red-color);
      }

      @include small-tablet {
        font-family: var(--second-family);
        font-size: 40px;
      }
    }

  }
}

.social-list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include small-tablet {
    justify-content: center;
  }

  &__item {

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__link {

    & svg path {
      transition: fill .3s ease-in-out;
    }

    @include hover {

      & svg path {
        fill: var(--red-color);
      }
    }
  }
}
