.sponsors {

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
  }

  &__title {

    @include small-tablet {
      margin-bottom: 48px;
    }
  }

  &__list {
    display: flex;
    gap: 48px;
    margin-bottom: 100px;

    @include small-tablet {
      margin-bottom: 48px;
    }
  }

  &__descr {
    margin: 0;
    font-family: var(--third-family);
    font-weight: 700;
    font-size: var(--32-20);
    line-height: 100%;
    color: #c6c6c6;
    text-align: center;
  }
}
