.glide {

  &__track {
    overflow: visible;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    padding: 14px 16px;
    margin-top: -9px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 12px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--text-color);
    z-index: 1;

    &--left {
      left: 0;
      // background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 12.75H5M5 12.75L12 5.75M5 12.75L12 19.75' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    }

    &--right {
      right: 0;
      // background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.75H19M19 12.75L12 5.75M19 12.75L12 19.75' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    }

    & svg {
      transition: stroke .3 ease-in-out;
    }

    @include hover {
      background-color: var(--red-color);

      & svg path {
        stroke: #fff;
      }
    }
  }
}

.glide__slide {
  width: auto!important;
  height: 100%!important;
}
