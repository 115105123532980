// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Roboto-Medium", sans-serif;
  --second-family: "Bebas Neue Cyrillic", sans-serif;
  --third-family: "Bebas Neue", sans-serif;
  --content-width: 1344px;
  --container-offset: 48px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --text-color: #c6c6c6;
  --grey-color: #1a1a1a;;
  --dark-color: #000;
  --red-color: #f42828;
  --16-12: clamp(0.75rem, 0.679rem + 0.357vw, 1rem);
  --20-12: clamp(0.75rem, 0.607rem + 0.714vw, 1.25rem);
  --20-14: clamp(0.875rem, 0.768rem + 0.536vw, 1.25rem);
  --20-16: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
  --24-16: clamp(1rem, 0.857rem + 0.714vw, 1.5rem);
  --32-16: clamp(1rem, 0.714rem + 1.429vw, 2rem);
  --32-20: clamp(1.25rem, 1.036rem + 1.071vw, 2rem);
  --40-24: clamp(1.5rem, 1.214rem + 1.429vw, 2.5rem);
  --64-48: clamp(3rem, 2.714rem + 1.429vw, 4rem);
  --96-48: clamp(3rem, 2.143rem + 4.286vw, 6rem);
  --120-48: clamp(3rem, 1.714rem + 6.429vw, 7.5rem);
}
