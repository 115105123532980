.swiper {

  &-button {

    &-next,
    &-prev {
      padding: 14px;
      width: 52px;
      height: 52px;
      border-radius: 12px;
      background-color: #242424;
      transition: background-color .3s ease-in-out;

      @include hover {
        background-color: var(--red-color);
      }

      &::after {
        content: unset;
      }
    }
  }
}

@media (max-width: 576px) {
  .gallery__list {
    flex-wrap: nowrap;
  }

  .gallery__list.swiper {
    width: 100%;
  }

  .gallery__list-item.swiper-slide {
    width: auto;
    flex-shrink: 0;
  }

  .swiper-wrapper {
    display: flex;
  }

}

.swiper.people__slider {
  width: 100%;
  margin-bottom: 24px;

  & .swiper-button-next,
  & .swiper-button-prev {
    margin-top: calc(-7px - var(--swiper-navigation-size) / 2);

    @include mobile {
      display: none;
    }
  }

  & .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 0px);
  }

  & .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, 0px);
  }
}

.swiper--after,
.swiper--past {
  overflow: visible;

  & .swiper-button-next,
  & .swiper-button-prev {
    background-color: #e6e6e6;
    transition: background-color .3s ease-in-out;

    @include hover {
      background-color: var(--red-color);
    }

    @include mobile {
      display: none;
    }
  }

  & .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 0px);
  }

  & .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, 0px);
  }
}

.gallery {

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(7, 139px);
    grid-auto-flow: dense;
    column-gap: 16px;
    row-gap: 20px;


    &-item {
      border-radius: 8px;
      overflow: hidden;
      object-fit: cover;

      &:nth-child(10n+1) {
        grid-column: 1 / span 1;
        grid-row: span 3;
      }

      &:nth-child(10n+2) {
        grid-column: 2 / span 1;
        grid-row: span 2;
      }

      &:nth-child(10n+3) {
        grid-column: 3 / span 2;
        grid-row: span 3;
      }

      &:nth-child(10n+4) {
        grid-column: 1 / span 1;
        grid-row: span 2;
      }

      &:nth-child(10n+5) {
        grid-column: 2 / span 1;
        grid-row: span 2;
      }

      &:nth-child(10n+6) {
        grid-column: 3 / span 1;
        grid-row: span 2;
      }

      &:nth-child(10n+7) {
        grid-column: 4 / span 1;
        grid-row: span 2;
      }

      &:nth-child(10n+8) {
        grid-column: 1 / span 1;
        grid-row: span 2;
      }

      &:nth-child(10n+9) {
        grid-column: 2 / span 1;
        grid-row: span 3;
      }

      &:nth-child(10n+10) {
        grid-column: 3 / span 2;
        grid-row: span 2;
      }

      & img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
      }

      @include mobile {
        width: auto;
        height: 100%;
        max-height: 300px;
      }

    }
  }
}


@media (max-width: 576px) {
  .people .gallery__list {
    display: flex;
  }
}

.btn--people {
  position: relative;
  padding: 12px;
  text-transform: uppercase;
  font-family: var(--second-family);
  overflow: hidden;
  border-radius: 36px;
  border: 2px solid transparent;
  transition: border-color .3s ease-in-out;

  @include mobile {
    padding: 2px;
    border-radius: 20px;
  }

  & span {
    position: absolute;
    bottom: 20px;
    left: 50%;
    display: flex;
    margin-top: -10px;
    padding: 5px 4px 0px 4px;
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    border-radius: 4px;
    background-color: #9A0019;
    transform: translateX(-50%);

    @include mobile {
      bottom: 7px;
      padding: 5px 3px 0px 3px;
      font-size: 12px;
    }
  }

  @include hover {
    border-color: #f00;
  }
}
