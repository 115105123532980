*,
*::before,
*::after {
  box-sizing: border-box;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: var(--dark-color);
}

img {
  height: auto;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--text-color);
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 50px var(--container-offset);
  max-width: var(--container-width);
  color: var(--text-color);

  @include mobile {
    padding: 24px 8px;
  }
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

// .burger {
//   --burger-width: 18px;
//   --burger-height: 18px;
//   --burger-line-height: 2px;

//   position: relative;
//   border: none;
//   padding: 0;
//   width: var(--burger-width);
//   height: var(--burger-height);
//   color: #141414;
//   background-color: #141414;
//   cursor: pointer;

//   &::before,
//   &::after {
//     content: "";
//     position: absolute;
//     left: 0;
//     width: 100%;
//     height: var(--burger-line-height);
//     background-color: currentColor;
//     transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
//     z-index: 5;
//   }

//   &::before {
//     top: 0;
//   }

//   &::after {
//     top: calc(100% - var(--burger-line-height));
//   }

//   &__line {
//     position: absolute;
//     left: 0;
//     top: 50%;
//     width: 100%;
//     height: var(--burger-line-height);
//     background-color: currentColor;
//     transform: translateY(-50%);
//     transition: transform 0.3s ease-in-out;
//     z-index: 1;

//     &::before {
//       content: '';
//       position: absolute;
//       position: absolute;
//       top: -20px;
//       bottom: -27px;
//       right: -15px;
//       left: -17px;
//       background-color: #141414;
//       border-radius: 50%;
//       z-index: -1;
//     }
//   }

//   &--active {
//     &::before {
//       color: #ffffff;
//       top: 50%;
//       transform: rotate(45deg);
//       transition: transform 0.3s ease-in-out, top 0.3s ease-in-out, color .3s ease-in-out;
//     }

//     &::after {
//       color: #ffffff;
//       top: 50%;
//       transform: rotate(-45deg);
//       transition: transform 0.3s ease-in-out, top 0.3s ease-in-out, color .3s ease-in-out;
//     }

//     .burger__line {
//       transform: scale(0);
//       transition: transform 0.3s ease-in-out;
//     }
//   }
// }

.burger {
  .line {
    // Close transition
    transition: top 0.2s 0.2s, transform 0.2s, opacity 0.2s;
  }
}

.openbtn {
  background: #141414;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.openbtn .openbtn-area{
  transition: all .4s;
  width: 46px;
  height: 46px;
}

.openbtn span {
  position: absolute;
  background: #fff;
  display: inline-block;
  // border-radius: 3px;
  left: 12px;
  height: 2px;
  transition: all .4s;
  width: 50%;
  &:nth-of-type(1) {
      top: 14px;
  }
  &:nth-of-type(2) {
      top: 22px;
  }
  &:nth-of-type(3) {
      top: 30px;
  }
}

.openbtn.burger--active .openbtn-area {
  transform: rotate(360deg);
}


.openbtn.burger--active span{
  &:nth-of-type(1) {
      width: 45%;
      top: 16px;
      left: 14px;
      transform: translateY(6px) rotate(-45deg);
  }
  &:nth-of-type(2) {
      opacity: 0;
  }
  &:nth-of-type(3) {
      width: 45%;
      top: 28px;
      left: 14px;
      transform: translateY(-6px) rotate(45deg);
  }
}



.burger {
  display: none;
  color: var(--light-color);

  @media (max-width:768px) {
    display: block;
  }
}

.nav.menu--active {
  visibility: visible;
  transform: none;
  display: flex;
  opacity: 1;
  height: 100%;
  overflow: scroll;
}

.nav {

  @media (max-width:768px) {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 150px 25px 25px 25px;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    visibility: hidden;
    background-color: #000000;
    opacity: 0;
    transition: all .3s ease-in-out;
  }

}

.section-title {
  margin: 0;
  margin-bottom: 24px;
  font-family: var(--second-family);
  font-size: var(--96-48);
  line-height: 125%;
  text-align: center;
  color: #c6c6c6;
}

.btn {
  padding: 20px 100px;
  font-size: var(--24-16);
  line-height: 100%;
  border-radius: 8px;
  font-family: var(--second-family);
  background-color: var(--red-color);
  transition: transform .3s ease-in-out;

  @include hover {
    transform: scale(1.05);
  }

  &--secondary {
    border-radius: 44px;
    padding: 12px 24px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: var(--20-12);
    line-height: 120%;
    background-color: var(--grey-color);
    transition: background-color .3s ease-in-out;

    @include hover {
      background-color: var(--red-color);
      transform: none;
    }
  }

}
